import { Suspense, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import useWindowSize from '../hooks/useWindowSize';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function MainLayout() {
    const width = useWindowSize();
    return (
        <>
            <Suspense>
                <Outlet />
            </Suspense>
            <ScrollToTop />
        </>

    )
}

export default MainLayout